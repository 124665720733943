// import { ToastContainer } from "react-toastify";


const StateTest = () => {
    return(
        <>
            <h1>Hi</h1>
        </>
    );
}

export default StateTest;